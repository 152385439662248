import React from 'react';
// import { SubscribeModal } from 'gatsby-theme-newsletter/src/components/SubscribeModal';

import Layout from '../../../../components/Layout';
import SEO from '../../../../components/Seo';

import styles from './index.module.scss';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const IndexPage = () => (
  <Layout>
    <SEO
      title="Waiter - Jetzt Demo anfordern! | QRpid Next Level QR-Codes"
      description="Web based software to manage anything with our next level QR-Codes for service and support, distribution, tracking and feedback solutions."
      keywords={[`qrcodes`, `assets`, `devices`, `manage`]}
    />
    <section className={styles.steps__light}>
      <div className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className="cell small-12">
            <h1>Demo anfordern!</h1>
            <p>Hier kommt noch das Formular um eine Demo anzufordern.</p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
